import './src/styles/global.scss';
import '@fortawesome/fontawesome-svg-core/styles.css';

// gatsby-browser.js
export const onRouteUpdate = ({ location }) => {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: 'gatsby-route-change',
      page: location.pathname,
    });
  }
};